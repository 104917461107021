import { Routes, Route } from "react-router-dom";
import Landing from "./screens/landing";
import Menu from "./screens/menu";
import Order from "./screens/order";


export default function RouteContainer() {
    return (
        <Routes>
            <Route path='/' element={<Landing/>}>Brasumadre</Route>
            <Route path='/menu' element={<Menu/>}>Brasumadre - Menu</Route>
            <Route path='/pedir' element={<Order/>}>Brasumadre - Pedir</Route>
        </Routes>
    )
}