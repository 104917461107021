import React, {useEffect, useState} from "react";
import styles from '../styles/landing.module.css';
import {Link} from "react-router-dom";

export default function Landing() {
    const [screenWidth, setScreenWidth] = useState(window.innerWidth);

    useEffect(() => {
        const handleResize = () => {
            setScreenWidth(window.innerWidth);
        };

        window.addEventListener("resize", handleResize);

        // Cleanup the event listener on component unmount
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    const getImageSrc = () => {
        if (screenWidth > 1000) {
            return '/menu/landingLG.png'; // Large image for screens wider than 1000px
        } else {
            return '/menu/landingSM.png'; // Small image for screens smaller than or equal to 1000px
        }
    };

    return (
        <div className={styles.mainContainer}>
            <div className={styles.headerBrasu}>
                <div>Jr. Ayacucho 558, Magdalena</div>
                <div>
                    <Link to={'/'} className={'me-2'}>
                        <img src={'/facebook.png'} width={'30px'} alt={'facebook'}/>
                    </Link>
                    <Link to={'/'} className={'me-2'}>
                        <img src={'/instagram.png'} width={'30px'} alt={'instagram'}/>
                    </Link>
                    <Link to={'/'} className={'me-2'}>
                        <img src={'/tiktok.png'} width={'30px'} alt={'tiktok'}/>
                    </Link>
                </div>
            </div>

            <div className={styles.mainImageContainer}>
                <img src={'/menu/logo.jpg'} alt={'Brasumadre'} className={styles.mainImage}/>
                <div className={`${styles.mainTextContainer} mt-2`}>
                    <div>Visítanos:</div>
                    <div>Jr. Ayacucho 558, Magdalena</div>
                    <div className={'my-2'}>
                        <Link to={'https://www.rappi.com.pe/restaurantes/50815-brasumadre'} className={`${styles.rappiButton}`}>
                            Pedir por <b>Rappi</b>
                        </Link>
                    </div>
                </div>
            </div>

            <img src={getImageSrc()} alt="Chicken 3" className={styles.bgImage}/>
            <div className={styles.container}>
                <Link to={'/'}>
                    <img src={'/menu/panel1.png'} alt={'logo'} className={`${styles.image}`}/>
                </Link>
                <Link to={'/menu'}>
                    <img src={'/menu/panel2.png'} alt={'logo'} className={`${styles.image} ${styles.centerImage}`}/>
                </Link>
                <Link to={'/pedir'}>
                    <img src={'/menu/panel3.png'} alt={'logo'} className={styles.image}/>
                </Link>
            </div>
        </div>
    )
}
