import React from "react";
import styles from '../styles/order.module.css';
import {Link} from "react-router-dom";

export default function Order() {
    return (
        <div className={styles.container}>
            <div className={`pt-4`}>
                <Link to={'/'}>
                    <img src={'/menu/logo.png'} alt={'logo'} className={styles.mainLogo}/>
                </Link>
            </div>
            <div className={styles.optionsContainer}>
                <div className={styles.option} style={{backgroundImage: "url(/menu/landingSM.png)"}}>
                    <div className={styles.optionInfoContainer}>
                        <div>
                            Visítanos:
                        </div>
                        <div>
                            Jr. Ayacucho 558, Magdalena
                        </div>
                        <Link className={styles.rappiButton} to={'https://www.rappi.com.pe/restaurantes/50815-brasumadre'}>
                            Como llegar
                        </Link>
                    </div>
                </div>
                <div className={styles.option} style={{backgroundImage: "url(/menu/landingSM.png)"}}>
                    <div className={styles.optionInfoContainer}>
                        <div>
                            Pide un Rappi
                        </div>
                        <div>
                            Encuéntranos en la App
                        </div>
                        <Link className={styles.rappiButton} to={'https://www.rappi.com.pe/restaurantes/50815-brasumadre'}>
                            Pedir por <b>Rappi</b>
                        </Link>
                    </div>
                </div>
                <div className={styles.option} style={{backgroundImage: "url(/menu/landingSM.png)"}}>
                    <div className={styles.optionInfoContainer}>
                        <div>
                            CONTACTO
                        </div>
                        <div>
                            Teléfono 01 681 8794 <br/>
                            WhatsApp 973 756 715
                        </div>
                        <Link className={styles.rappiButton} to={'https://www.rappi.com.pe/restaurantes/50815-brasumadre'}>
                            Chatea
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    )
}