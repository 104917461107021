import React from "react";
import styles from '../styles/menu.module.css';
import {Link} from "react-router-dom";

export default function Menu() {

    const products = [
        {
            title: "Pollos",
            prods: [
                {
                    title: "Pollos a la brasa",
                    desc: "Jugoso pollo a las brasas de carbón con un toque ahumado, al único estilo Brasumadre, acompañado de ensalada fresca con vinagreta de la casa, ensalada de col dulce y nuestras papas onduladas crocantes.",
                    prices: [
                        {
                            title: "1 pollo",
                            price: "72.90"
                        },
                        {
                            title: "1/2 pollo",
                            price: "42.90"
                        },
                        {
                            title: "1/4 pollo",
                            price: "22.90"
                        },
                    ]
                },
                {
                    title: "Milanesa de pollo",
                    desc: "Impresionante filete de 200 grs. de pechuga rebozada y crujiente al estilo tempura, servida al plato junto a una generosa porción de crocantes papas fritas.",
                    prices: [
                        {
                            title: "",
                            price: "18.50"
                        }
                    ]
                }
            ]
        },
        {
            title: "Sanguchazos",
            prods: [
                {
                    title: "Pechuga Crispy",
                    desc: "Gran filete de pechuga rebozada y crujiente, en pan brioche untado con salsa golf especial, con base de mix de lechugas y coronado por onion rings, bañado con nuestra salsa de pimientos morrones. Lo servimos en bandeja acompañado de papas fritas.",
                    prices: [
                        {
                            title: "",
                            price: "24.50"
                        }
                    ]
                },
                {
                    title: "De Pollo a la Brasa",
                    desc: "¡Todo el sabor de nuestro pollo hecho sánguche! Grandes trozos de pollo a la brasa sobre la ensalada de col de la casa, en pan brioche tostado y untado con nuestra mayonesa de la casa, servido al plato con camote frito glaseado con salsa de miel y aroma de vainilla. ¡Una combinación única!",
                    prices: [
                        {
                            title: "",
                            price: "22.50"
                        }
                    ]
                }
            ]
        },
        {
            title: "Para picar",
            prods: [
                {
                    title: "Salchipapas",
                    desc: "¡Porque no sólo de pollo vive el hombre! Sabroso frankfurter a la plancha en láminas sobre una generosa porción de nuestras papas fritas. Servido en plato hondo, un clásico para picar entre varios o darse un gran gusto en solitario.",
                    prices: [
                        {
                            title: "",
                            price: "16.90"
                        }
                    ]
                },
                {
                    title: "Salchibrasa",
                    desc: "Un gran salchipapas acompañado de trozos de nuestro gran pollo a la brasa. Un generoso plato para acompañar con nuestro ají y mayonesas de la casa.",
                    prices: [
                        {
                            title: "",
                            price: "18.00"
                        }
                    ]
                },
                {
                    title: "Papas Cheddar",
                    desc: "Nuestras crocantes papas fritas onduladas, bañadas con sabrosísima salsa de queso cheddar y cebollita china picada. Perfectas para acompañar una chelita.",
                    prices: [
                        {
                            title: "",
                            price: "18.50"
                        }
                    ]
                },
                {
                    title: "Supertequeños de queso",
                    desc: "¡Tan grandes que casi los llamamos empanadas! Masa extra large rellena de puro y sabroso queso Paria peruano, servidos con bastante salsa guacamole.",
                    prices: [
                        {
                            title: "6 unds",
                            price: "20.90"
                        }
                    ]
                },
                {
                    title: "Supertequeños de queso y pollo a la brasa",
                    desc: "Como si no fueran ya suficientemente perfectos, a esta versión le agregamos nuestro jugoso pollo a la brasa junto al queso Paria. Los tequeños nunca serán los mismos después de que pruebes estos. Y nunca nos ajustamos con la salsa guacamole :)",
                    prices: [
                        {
                            title: "6 unds",
                            price: "22.90"
                        }
                    ]
                }
            ]
        },
        {
            title: "Guarniciones",
            prods: [
                {
                    title: "Papas fritas",
                    desc: "Crocantes papas onduladas, fritas al punto y siempre calientitas.",
                    prices: [
                        {
                            title: "",
                            price: "12.00"
                        }
                    ]
                },
                {
                    title: "Camote frito glaseado",
                    desc: "Finas rodajas de camote amarillo frito y glaseado con salsa dulce con aroma a vainilla.",
                    prices: [
                        {
                            title: "",
                            price: "8.00"
                        }
                    ]
                },
                {
                    title: "Ensalada fresca",
                    desc: "La que servimos junto a nuestro pollo a la brasa. Mix de lechugas, tomate fresco, palta cremosa con ajonjolí, zanahoria rallada, pepino y rabanito. Servida con nuestra vinagreta de la casa con ajo y hierbas.",
                    prices: [
                        {
                            title: "Personal",
                            price: "8.00"
                        },
                        {
                            title: "Grande",
                            price: "12.00"
                        },
                    ]
                },
                {
                    title: "Ensalada de col dulce",
                    desc: "La fiel compañera que complementa a nuestro pollo a la brasa. Receta de la casa con col blanca y zanahoria.",
                    prices: [
                        {
                            title: "Personal",
                            price: "4.50"
                        },
                        {
                            title: "Grande",
                            price: "9.90"
                        },
                    ]
                }
            ]
        },
        {
            title: "Postres y Bebidas",
            prods: [
                {
                    title: "Torta de chocolate artesanal",
                    desc: "",
                    prices: [
                        {
                            title: "",
                            price: "12.90"
                        },
                    ]
                },
                {
                    title: "Chicha Morada",
                    desc: "",
                    prices: [
                        {
                            title: "1lt",
                            price: "12.00"
                        },
                        {
                            title: "Vaso",
                            price: "6.00"
                        }
                    ]
                },
                {
                    title: "Refresco de maracuyá",
                    desc: "",
                    prices: [
                        {
                            title: "1lt",
                            price: "12.00"
                        },
                        {
                            title: "Vaso",
                            price: "6.00"
                        }
                    ]
                },
                {
                    title: "Gaseosas",
                    desc: "",
                    prices: [
                        {
                            title: "",
                            price: "5.00"
                        }
                    ]
                }
            ]
        }
    ]

    return (
        <div className={`${styles.container}`}>
            <div className={`${styles.headerContainer} pt-4`}>
                <Link to={'/'}>
                    <img src={'/menu/logo.png'} alt={'logo'} className={styles.mainLogo}/>
                </Link>
            </div>

            <div className={`${styles.products} mt-5 px-5 py-3`}>
                {products.map((category, index) => (
                    <div className={`${styles.category}`} key={index}>
                        <div className={styles.categoryTitle}>{category.title}</div>
                        <div className={'d-flex flex-row flex-wrap'}>
                        {category.prods.map((prod, prodIndex) => (
                            <div className={`${styles.product} mb-3`} key={prodIndex}>
                                <div className={styles.prodTitle}>{prod.title}</div>
                                <div className={`${styles.prodDesc} mb-2`}>{prod.desc}</div>
                                {prod.prices.map((price, priceIndex) => (
                                    <div key={priceIndex}>
                                        <div className={`${styles.priceText}`}>{price.title} {price.title !== "" && '-'} S/.{price.price}</div>
                                    </div>
                                ))}
                            </div>
                        ))}
                        </div>
                    </div>
                ))}
            </div>

            <div className={styles.noteText}>* Carta con precios de atención en nuestro salón. Para delivery por Rappi consultar la plataforma.</div>
        </div>
    )
}